import {T_S_Datas,T_LastInfo,T_CITY,T_ZONING,T_USER_DATAS,T_DEDUCTED_DATAS,T_DEDUCTED_DATA_TYPE,T_CITY_ZONES,T_CITIES_ZONES,T_CITY_ZONE} from './interfaces';
import {
  D_LAST_INFO,
  D_LEPERRAYENYVELINES,
  D_LESESSARTSLEROI,
  D_AUFFARGIS,
  D_LESBREVIAIRES
} from '../datas';

import S_Utils  from "./utils";


let S_Datas : T_S_Datas = {
  STORAGE_KEY_INFOREAD : 'poubelles_inforead',
  STORAGE_KEY_ZONING : 'poubelles_zoning',

  lastInfo: undefined,
  allDatas: {},

  currentZoning: {
    city: undefined,
    divers: undefined,
    plastiques: undefined,
    verre: undefined,
    vegetaux: undefined
  },
  currentInfoRead: undefined,


  readAll: function(){
    console.log('readAll...');

    // donnees embarquees
    S_Datas.lastInfo = D_LAST_INFO;
    S_Datas.allDatas = {
      'auffargis': D_AUFFARGIS,
      'lesbreviaires': D_LESBREVIAIRES,
      'leperrayenyvelines': D_LEPERRAYENYVELINES,
      'lesessartsleroi': D_LESESSARTSLEROI
    };


    // donnees utilisateur
    S_Datas.currentZoning = {
      city: 'auffargis',
      divers: undefined,
      plastiques: undefined,
      verre: undefined,
      vegetaux: undefined
    };
    let storageZoning = window.localStorage[S_Datas.STORAGE_KEY_ZONING];
    if (storageZoning) {
      try{
        S_Datas.currentZoning = JSON.parse(storageZoning);
      }catch(err){
        console.error('Cannot parse localStorage',S_Datas.STORAGE_KEY_ZONING,'value, reset it...');
        S_Datas.currentZoning = {
          city: 'auffargis',
          divers: undefined,
          plastiques: undefined,
          verre: undefined,
          vegetaux: undefined
        };
        window.localStorage.removeItem(S_Datas.STORAGE_KEY_ZONING);
      }
    }
    // recuperation des anciennes valeurs
    else {
      let storageDeprecatedCity = window.localStorage['poubelles_city_id'];
      if (storageDeprecatedCity && storageDeprecatedCity !== 'undefined') {
        S_Datas.currentZoning = {
          city: storageDeprecatedCity,
          divers: undefined,
          plastiques: undefined,
          verre: undefined,
          vegetaux: undefined
        };
        window.localStorage.removeItem(S_Datas.STORAGE_KEY_ZONING);
      }
    }

    let infoRead = window.localStorage[S_Datas.STORAGE_KEY_INFOREAD];
    try{
      S_Datas.currentInfoRead = infoRead!==undefined ? JSON.parse(infoRead) : undefined;
    }catch(err){
      console.error('Cannot parse localStorage',S_Datas.STORAGE_KEY_INFOREAD,'value, reset it...');
      S_Datas.currentInfoRead = undefined;
      window.localStorage.removeItem(S_Datas.STORAGE_KEY_INFOREAD);
    }

    console.log('Embeded datas : ',S_Datas.allDatas);
    console.log('Current location : ',S_Datas.currentZoning);
    console.log('Current info read : ',S_Datas.currentInfoRead);
  },

  fetch(cb: Function, cbE: Function) {
    console.log('fetch...');
    // donnees externalisees
    const URL_LASTINFO = process.env.REACT_APP_ENV !== 'production' ? 'https://poubelles.asnidren.com/assets/json/lastInfo.php?d='+(new Date()).getTime() : 'https://poubelles.asnidren.com/assets/json/lastInfo.json?d='+(new Date()).getTime();
    const OPTIONS_LASTINFO = {
      method: 'GET',
      // headers: { // ca l'appelle 2fois sinon, sur le OPTIONS, quand on passe headers
      //   "Content-Type": "application/json",
      // },
    }
    fetch(
      URL_LASTINFO,
      OPTIONS_LASTINFO
    )
    .then(async(res) => {
      // console.log({res});
      const status = res.status;
      const body = await res.json();
      console.log('datas - fetch returned : ',{status,body});
      if (status === 200){
        if (S_Datas.lastInfo === undefined || body.id > S_Datas.lastInfo.id) {
          S_Datas.lastInfo = body;
          console.log('datas - status is now : ',{lastInfo: S_Datas.lastInfo,currentInfoRead: S_Datas.currentInfoRead});
        }else{
          console.log('datas - Online info is older than embeded one, status is now : ',{lastInfo: S_Datas.lastInfo,currentInfoRead: S_Datas.currentInfoRead});
        }
        cb();
      }
    })
    .catch((err) => {
      console.error(err);
      cbE();
    });
  },

  // renvoie les datas brutes de la ville / zones selectionnees
  getDatas: function():T_USER_DATAS{
    if (!S_Datas.allDatas || Object.keys(S_Datas.allDatas).length === 0) {
      console.log('Cannot get datas, no embedded datas.');
      return {};
    }

    const cities = Object.keys(S_Datas.allDatas);
    if (!S_Datas.currentZoning || !S_Datas.currentZoning.city || cities.indexOf(S_Datas.currentZoning.city) === -1) {
      console.log('Cannot get datas with empty or invalid currentZoning.');
      return {};
    }

    const cityID = S_Datas.currentZoning.city;
    const diversZone = S_Datas.currentZoning.divers;
    const plastiquesZone = S_Datas.currentZoning.plastiques;
    const verreZone = S_Datas.currentZoning.verre;
    const vegetauxZone = S_Datas.currentZoning.vegetaux;

    let currentCityDatas : T_CITY = (S_Datas.allDatas as any)[cityID];

    let collectTypes = currentCityDatas.collectTypes;
    const diversZones = collectTypes && collectTypes.divers ? Object.keys(collectTypes.divers) : undefined;
    const plastiquesZones = collectTypes && collectTypes.divers ? Object.keys(collectTypes.plastiques) : undefined;
    const verreZones = collectTypes && collectTypes.divers ? Object.keys(collectTypes.verre) : undefined;
    const vegetauxZones = collectTypes && collectTypes.vegetaux ? Object.keys(collectTypes.vegetaux) : undefined;

    let collectDivers = undefined;
    if (!diversZone && diversZones && diversZones.length === 1) {  // si y'a qu'une zone, on la selectionne
      collectDivers = collectTypes.divers[diversZones[0]];
    }
    else if (diversZones && diversZones.length > 1) {
      if (diversZone && diversZones.indexOf(diversZone) !== -1) {
        collectDivers = collectTypes.divers[diversZone];
      }
    }

    let collectPlastiques = undefined;
    if (!plastiquesZone && plastiquesZones && plastiquesZones.length === 1) {  // si y'a qu'une zone, on la selectionne
      collectPlastiques = collectTypes.plastiques[plastiquesZones[0]];
    }
    else if (plastiquesZones && plastiquesZones.length > 1) {
      if (plastiquesZone && plastiquesZones.indexOf(plastiquesZone) !== -1) {
        collectPlastiques = collectTypes.plastiques[plastiquesZone];
      }
    }

    let collectVerre = undefined;
    if (!verreZone && verreZones && verreZones.length === 1) {  // si y'a qu'une zone, on la selectionne
      collectVerre = collectTypes.verre[verreZones[0]];
    }
    else if (verreZones && verreZones.length > 1) {
      if (verreZone && verreZones.indexOf(verreZone) !== -1) {
        collectVerre = collectTypes.verre[verreZone];
      }
    }

    let collectVegetaux = undefined;
    if (!vegetauxZone && vegetauxZones && vegetauxZones.length === 1) {  // si y'a qu'une zone, on la selectionne
      collectVegetaux = collectTypes.vegetaux[vegetauxZones[0]];
    }
    else if (vegetauxZones && vegetauxZones.length > 1) {
      if (vegetauxZone && vegetauxZones.indexOf(vegetauxZone) !== -1) {
        collectVegetaux = collectTypes.vegetaux[vegetauxZone];
      }
    }

    const userCurrentDatasToDisplay = {
      id: currentCityDatas.id,
      labelCity: currentCityDatas.labelCity,
      warningCity: currentCityDatas.warningCity,
      lastUpdate: currentCityDatas.lastUpdate,
      documents: currentCityDatas.documents,
      divers: collectDivers,
      plastiques: collectPlastiques,
      verre: collectVerre,
      vegetaux: collectVegetaux,
    };
    console.log('Datas - getDatas : ',{userCurrentDatasToDisplay});
    return userCurrentDatasToDisplay;
  },

  // renvoie les datas deduites a partir des datas brutes
  getDeductedDatas: function(userDatas:T_USER_DATAS):T_DEDUCTED_DATAS{

    let currentDate = S_Utils.getCurrentDate();

    let divers:T_DEDUCTED_DATA_TYPE = {
      hasnext: undefined,
      date: undefined,
      alert: undefined,
      next: undefined
    };
    if (userDatas.divers && userDatas.divers.days){
      if (userDatas.divers.days==='auto'){
        divers.hasnext = true;
      }
      else {
        for(var i = 0; i < userDatas.divers.days.length; i++){
          var nextDate = S_Utils.getNextDate(userDatas.divers.days[i]);
          if (nextDate.getTime() >= currentDate.getTime()){
            divers.date = S_Utils.formatDate(nextDate);
            divers.alert = S_Utils.formatAlert(currentDate, nextDate);
            divers.next = S_Utils.formatLabel(currentDate, nextDate);
            divers.hasnext = true;
            break;
          }
        }
      }
    }

    let plastiques:T_DEDUCTED_DATA_TYPE = {
      hasnext: undefined,
      date: undefined,
      alert: undefined,
      next: undefined
    };
    if (userDatas.plastiques && userDatas.plastiques.days){
      if (userDatas.plastiques.days==='auto'){
        plastiques.hasnext = true;
      }
      else {
        for(var i = 0; i < userDatas.plastiques.days.length; i++){
          var nextDate = S_Utils.getNextDate(userDatas.plastiques.days[i]);
          if (nextDate.getTime() >= currentDate.getTime()){
            plastiques.date = S_Utils.formatDate(nextDate);
            plastiques.alert = S_Utils.formatAlert(currentDate, nextDate);
            plastiques.next = S_Utils.formatLabel(currentDate, nextDate);
            plastiques.hasnext = true;
            break;
          }
        }
      }
    }

    let verre:T_DEDUCTED_DATA_TYPE = {
      hasnext: undefined,
      date: undefined,
      alert: undefined,
      next: undefined
    };
    if (userDatas.verre && userDatas.verre.days){
      if (userDatas.verre.days==='auto'){
        verre.hasnext = true;
      }
      else {
        for(var i = 0; i < userDatas.verre.days.length; i++){
          var nextDate = S_Utils.getNextDate(userDatas.verre.days[i]);
          if (nextDate.getTime() >= currentDate.getTime()){
            verre.date = S_Utils.formatDate(nextDate);
            verre.alert = S_Utils.formatAlert(currentDate, nextDate);
            verre.next = S_Utils.formatLabel(currentDate, nextDate);
            verre.hasnext = true;
            break;
          }
        }
      }
    }

    let vegetaux:T_DEDUCTED_DATA_TYPE = {
      hasnext: undefined,
      date: undefined,
      alert: undefined,
      next: undefined
    };
    if (userDatas.vegetaux && userDatas.vegetaux.days){
      if (userDatas.vegetaux.days==='auto'){
        vegetaux.hasnext = true;
      }
      else {
        for(var i = 0; i < userDatas.vegetaux.days.length; i++){
          var nextDate = S_Utils.getNextDate(userDatas.vegetaux.days[i]);
          if (nextDate.getTime() >= currentDate.getTime()){
            vegetaux.date = S_Utils.formatDate(nextDate);
            vegetaux.alert = S_Utils.formatAlert(currentDate, nextDate);
            vegetaux.next = S_Utils.formatLabel(currentDate, nextDate);
            vegetaux.hasnext = true;
            break;
          }
        }
      }
    }

    let deductedDatas = {
      divers: divers,
      plastiques: plastiques,
      verre: verre,
      vegetaux: vegetaux
    };
    return deductedDatas;
  },

  // renvoie la carte des zonings
  getAllZonings: function():T_CITIES_ZONES {

    const citiesKeys = S_Datas.allDatas ? Object.keys(S_Datas.allDatas) : undefined;

    let allZonings:T_CITIES_ZONES = {};
    if (citiesKeys) {
      citiesKeys.forEach((cityKey) => {
        const cityDatas = (S_Datas.allDatas as any)[cityKey];

        let cityDiversZones:T_CITY_ZONE = {};
        if (cityDatas.collectTypes && cityDatas.collectTypes.divers) {
          const keys:string[] = Object.keys(cityDatas.collectTypes.divers);
          if (keys) {
            keys.forEach(zoneKey => {
              (cityDiversZones as any)[zoneKey] = cityDatas.collectTypes.divers[zoneKey].labelZone;
            });
          }
        }
        let cityPlastiquesZones:T_CITY_ZONE = {};
        if (cityDatas.collectTypes && cityDatas.collectTypes.plastiques) {
          const keys = Object.keys(cityDatas.collectTypes.plastiques);
          if (keys) {
            keys.forEach(key => {
              (cityPlastiquesZones as any)[key] = cityDatas.collectTypes.plastiques[key].labelZone;
            });
          }
        }
        let cityVerreZones:T_CITY_ZONE = {};
        if (cityDatas.collectTypes && cityDatas.collectTypes.verre) {
          const keys = Object.keys(cityDatas.collectTypes.verre);
          if (keys) {
            keys.forEach(key => {
              (cityVerreZones as any)[key] = cityDatas.collectTypes.verre[key].labelZone;
            });
          }
        }
        let cityVegetauxZones:T_CITY_ZONE = {};
        if (cityDatas.collectTypes && cityDatas.collectTypes.vegetaux) {
          const keys = Object.keys(cityDatas.collectTypes.vegetaux);
          if (keys) {
            keys.forEach(key => {
              (cityVegetauxZones as any)[key] = cityDatas.collectTypes.vegetaux[key].labelZone;
            });
          }
        }


        const cityZoning:T_CITY_ZONES = {
          labelCity: (S_Datas.allDatas as any)[cityKey].labelCity,
          divers: cityDiversZones,
          plastiques: cityPlastiquesZones,
          verre: cityVerreZones,
          vegetaux: cityVegetauxZones
        }
        allZonings[cityKey] = cityZoning;
      });
    }
    return allZonings;
  },

  // indique si il manque une selection de zone
  isZoningComplete: function():boolean {
    const allZonings = S_Datas.getAllZonings();

    if (!allZonings || Object.keys(allZonings).length === 0) {
      return true;
    }

    if (!S_Datas.currentZoning || !S_Datas.currentZoning.city || (Object.keys(allZonings)).indexOf(S_Datas.currentZoning.city) === -1) {
      console.log('Missing city selection');
      return false;
    }

    if (allZonings[S_Datas.currentZoning.city].divers) {
      // si il existe plusieurs zones dans divers, et qu'on a pas choisit
      const zonesKeys = Object.keys(allZonings[S_Datas.currentZoning.city].divers);
      if (zonesKeys.length > 1 &&
          (!S_Datas.currentZoning.divers || zonesKeys.indexOf(S_Datas.currentZoning.divers) === -1)) {
          console.log('Missing divers selection',{current:S_Datas.currentZoning.divers,zonesKeys});
          return false;
      }
    }
    if (allZonings[S_Datas.currentZoning.city].plastiques) {
      const zonesKeys = Object.keys(allZonings[S_Datas.currentZoning.city].plastiques);
      if (zonesKeys.length > 1 &&
        (!S_Datas.currentZoning.plastiques || zonesKeys.indexOf(S_Datas.currentZoning.plastiques) === -1)) {
          console.log('Missing plastiques selection');
          return false;
      }
    }
    if (allZonings[S_Datas.currentZoning.city].verre) {
      const zonesKeys = Object.keys(allZonings[S_Datas.currentZoning.city].verre);
      if (zonesKeys.length > 1 &&
        (!S_Datas.currentZoning.verre || zonesKeys.indexOf(S_Datas.currentZoning.verre) === -1)) {
          console.log('Missing verre selection');
          return false;
      }
    }
    if (allZonings[S_Datas.currentZoning.city].vegetaux) {
      const zonesKeys = Object.keys(allZonings[S_Datas.currentZoning.city].vegetaux);
      if (zonesKeys.length > 1 &&
        (!S_Datas.currentZoning.vegetaux || zonesKeys.indexOf(S_Datas.currentZoning.vegetaux) === -1)) {
          console.log('Missing vegetaux selection');
          return false;
      }
    }

    return true;
  },

  selectCity: function(cityID: string){
    S_Datas.currentZoning = {
      city: cityID,
      divers: undefined,
      plastiques: undefined,
      verre: undefined,
      vegetaux: undefined
    };
    // si la ville selectionnee a plusieurs zones pour chaque type, on selectionne par defaut la 1ere
    const allZonings = S_Datas.getAllZonings();
    if (allZonings[cityID] && allZonings[cityID].divers && Object.keys(allZonings[cityID].divers).length > 1) {
      let firstKey = Object.keys(allZonings[cityID].divers)[0];
      S_Datas.currentZoning.divers = firstKey;
    }
    if (allZonings[cityID] && allZonings[cityID].plastiques && Object.keys(allZonings[cityID].plastiques).length > 1) {
      let firstKey = Object.keys(allZonings[cityID].plastiques)[0];
      S_Datas.currentZoning.plastiques = firstKey;
    }
    if (allZonings[cityID] && allZonings[cityID].verre && Object.keys(allZonings[cityID].verre).length > 1) {
      let firstKey = Object.keys(allZonings[cityID].verre)[0];
      S_Datas.currentZoning.verre = firstKey;
    }
    if (allZonings[cityID] && allZonings[cityID].vegetaux && Object.keys(allZonings[cityID].vegetaux).length > 1) {
      let firstKey = Object.keys(allZonings[cityID].vegetaux)[0];
      S_Datas.currentZoning.vegetaux = firstKey;
    }

    window.localStorage[S_Datas.STORAGE_KEY_ZONING] = JSON.stringify(S_Datas.currentZoning);
    console.log('S_Datas - selectCity',{currentZoning:S_Datas.currentZoning});
  },

  selectDivers: function(zoneID: string) {
    S_Datas.currentZoning.divers = zoneID;
    window.localStorage[S_Datas.STORAGE_KEY_ZONING] = JSON.stringify(S_Datas.currentZoning);
  },
  selectPlastiques: function(zoneID: string) {
    S_Datas.currentZoning.plastiques = zoneID;
    window.localStorage[S_Datas.STORAGE_KEY_ZONING] = JSON.stringify(S_Datas.currentZoning);
  },
  selectVerre: function(zoneID: string) {
    S_Datas.currentZoning.verre = zoneID;
    window.localStorage[S_Datas.STORAGE_KEY_ZONING] = JSON.stringify(S_Datas.currentZoning);
  },
  selectVegetaux: function(zoneID: string) {
    S_Datas.currentZoning.vegetaux = zoneID;
    window.localStorage[S_Datas.STORAGE_KEY_ZONING] = JSON.stringify(S_Datas.currentZoning);
  },


  getLastInfo: function() {
    return S_Datas.lastInfo;
  },

  setInfoRead: function(info: T_LastInfo|undefined){
    S_Datas.currentInfoRead = info;
  }

};
export default S_Datas;
export type {T_LastInfo};
