import React from 'react';

import './newsBox.scss';

// On crée un composant purement Front, qui s'affranchit de la définition d'objects métier

interface I_PROPS {
  id: string;
  title: string;
  text: string;
}

function I_NewsBox(props : I_PROPS) {
  const {id,title,text} = props;
  return (
    <div className="newsBox">
      <div className="newsBoxID">{id}</div>
      <h3 dangerouslySetInnerHTML={{__html: title}}></h3>
      <div className="newsBoxText" dangerouslySetInnerHTML={{__html: text}}></div>
    </div>
  );
}
export default I_NewsBox;
