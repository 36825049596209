import Page from './documents';


// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import UserDataContext                from '../../datas/contexts/UserDataContext';

const DocumentsInContext = () => {
  // valeurs en context
  const {reducerState} = useContext(UserDataContext);
  const {userDatas} = reducerState;

  const userDocuments = userDatas?.documents;

  return <Page userDocuments={userDocuments} />;
};

export default DocumentsInContext;
