
const weekdays = new Array(7);
weekdays[0] = "Dimanche";
weekdays[1] = "Lundi";
weekdays[2] = "Mardi";
weekdays[3] = "Mercredi";
weekdays[4] = "Jeudi";
weekdays[5] = "Vendredi";
weekdays[6] = "Samedi";

const months = new Array(12);
months[0] = "Jan";
months[1] = "Fév";
months[2] = "Mars";
months[3] = "Avr";
months[4] = "Mai";
months[5] = "Juin";
months[6] = "Juil";
months[7] = "Août";
months[8] = "Sept";
months[9] = "Oct";
months[10] = "Nov";
months[11] = "Déc";

let S_Utils = {
  STORAGE_KEY_PREFIX : 'poubelles2',
  isLogDebug: process.env.REACT_APP_ENV !== 'production',

  getCurrentDate: function(){
    var currentDate = new Date();
    var currentDateInStr = currentDate.getFullYear() + '-' + ((currentDate.getMonth()+1) < 10 ? '0' : '') + (currentDate.getMonth()+1) + '-' + (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate() + 'T00:00:00Z';
    currentDate = new Date(currentDateInStr);
    return currentDate;
  },

  getNextDate: function(dateInStr: string){
    return new Date(dateInStr + 'T00:00:00Z');
  },

  formatDate: function(nextDate: Date){
    var day = weekdays[nextDate.getDay()] + ' ';
    var dayNumber = (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate() + ' ';
    // var month = ((nextDate.getMonth()+1) < 10 ? '0' : '') + (nextDate.getMonth()+1) ;
    var month = months[nextDate.getMonth()] + ' ';
    var year = nextDate.getFullYear();
    return day + dayNumber + month + year;
  },

  formatAlert: function(currentDate: Date, nextDate: Date){
    var ecartInDays = ((nextDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if(ecartInDays < 2)
      return 'imminent';
    if(ecartInDays <= 7)
      return 'soon';
    return '';
  },

  formatLabel: function(currentDate: Date, nextDate: Date){
    var ecartInDays = ((nextDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if(ecartInDays < 1)
      return 'Aujourd\'hui';
    if(ecartInDays < 2)
      return 'Demain';
    if(ecartInDays < 3)
      return 'Après-demain';
    if(ecartInDays < 4)
      return '3j';
    if(ecartInDays < 5)
      return '4j';
    if(ecartInDays < 6)
      return '5j';
    if(ecartInDays < 7)
      return '6j';
    if(ecartInDays < 8)
      return '7j';
    if(ecartInDays < 14)
      return '+7j';
    if(ecartInDays < 21)
      return '+2sem';
    if(ecartInDays < 31)
      return '+3sem';
    return '+1mois';
  },

  getResetNext: function(){
    var newReset: any = {};
    return newReset;
  },

  getToday: function(){
    var currentDate = S_Utils.getCurrentDate();
    return S_Utils.formatDate(currentDate);
  }


};
export default S_Utils;
