import React from 'react';

import pkg from '../../../package.json';

import './version.scss';

function Version() {
  return (
    <div className="version">
      { process.env.REACT_APP_ENV === 'development' ? 'dev' : ''}
      v.{ pkg.version }<br/>{ pkg.lastUpdate }
    </div>
  );
}
export default Version;
