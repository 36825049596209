import {USERDATACONTEXT_DEFAULT_VALUE} from '../contexts/UserDataContext';
import REDUCER_ACTIONS from './actions';

const defaultReducerState = USERDATACONTEXT_DEFAULT_VALUE;
const reducer = (
  state: any,
  action: any
) => {
	if (action.type === REDUCER_ACTIONS.SET_USERDATAS) {
		const {userDatas} = action.payload;
    console.log('reducer - SET_USERDATAS',{userDatas});
		return {...state,userDatas:userDatas};
	}
	if (action.type === REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS) {
		const {deductedUserDatas} = action.payload;
    console.log('reducer - SET_DEDUCTEDUSERDATAS',{deductedUserDatas});
		return {...state,deductedUserDatas:deductedUserDatas};
	}
	if (action.type === REDUCER_ACTIONS.SET_USERZONING) {
		const {userZoning} = action.payload;
    console.log('reducer - SET_USERZONING',{userZoning});
		return {...state,userZoning:userZoning};
	}
	// return state;
	throw new Error(`No matching "${action.type}" action type`);
};

export {defaultReducerState};
export default reducer;
