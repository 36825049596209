import React, {useEffect,useState,useReducer} from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import Home      from "./pages/home";
import Calendar     from "./pages/calendar";
import Documents from "./pages/documents";
import About     from "./pages/about";

import S_Datas   from "./services/datas";
import {T_LastInfo} from "./services/datas";

import './App.scss';

// --- DataContext ----
// On utilise le Context API pour faire descendre les datas emarquées
// sur tout l'arbre de composant
// Il n'y a pas de modifications appliquées dessus, donc pas besoin de reducer
import DataContext from './datas/contexts/DataContext';
import UserDataContext from './datas/contexts/UserDataContext';
// --- \DataContext ----


// --- Reducer ----
// On charge les données liés aux parametres user dans le reducer state
import reducer, {defaultReducerState} from './datas/reducers/reducer';
// --- \Reducer ----


function App() {
  const [ datasReady, setDatasReady ] = useState<boolean>(false);
  const [ allDatas, setAllDatas ] = useState<object>({});
  const [ lastInfo, setLastInfo ] = useState<T_LastInfo|undefined>(undefined);

  // valeurs dans le reducer
  const [reducerState,reducerDispatch] = useReducer(reducer,defaultReducerState);
  const {userDatas,deductedUserDatas,userZoning,userInfoRead} = reducerState;

  // On utilise useEffect pour lire les datas embarquées et les datas fetch une seule fois au mount du composant
  useEffect(() => {
    // on lit les donnees embarquées, normalement c'est synchrone
    S_Datas.readAll();
    setDatasReady(true);
    setAllDatas(S_Datas.allDatas);
    setLastInfo(S_Datas.lastInfo);

    // on repporte le userZoning, calculé dans le readAll
    const userZoning = S_Datas.currentZoning;
    reducerDispatch({type:'SET_USERZONING',payload:{userZoning}});

    // on calcule les données lié au user
    const userDatas = S_Datas.getDatas();
    reducerDispatch({type:'SET_USERDATAS',payload:{userDatas}});

    // on calcule les valeurs deduites
    let deductedUserDatas = userDatas ? S_Datas.getDeductedDatas(userDatas) : undefined;
    reducerDispatch({type:'SET_DEDUCTEDUSERDATAS',payload:{deductedUserDatas}});

    // console.log('App - useCallback');
    S_Datas.fetch(() => {
      // on prend l'info recuperee du fetch
      console.log('App - fetch ok',{lastInfo:S_Datas.lastInfo});
      setLastInfo(S_Datas.lastInfo);
    },() => {
      // si on en a une en localStorage
      if (S_Datas.currentInfoRead !== undefined) {
        console.log('App - fetch ko, take last info seen',{lastInfo:S_Datas.currentInfoRead});
        setLastInfo(S_Datas.currentInfoRead);
      }
      // sinon on prend cell qui est embeded
      else{
        console.log('App - fetch ko, take embeded info',{lastInfo:S_Datas.lastInfo});
        setLastInfo(S_Datas.lastInfo);
      }
    });
  },[]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/documents",
      element: <Documents />,
    },
    {
      path: "/calendrier",
      element: <Calendar />,
    },
    {
      path: "/assets",
      element: <Documents />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    },
  ], { basename: "/" });


  return (
    <div className="App background">
      <DataContext.Provider value={{datasReady,allDatas,lastInfo}}>
        <UserDataContext.Provider value={{reducerState:reducerState,reducerDispatch:reducerDispatch}}>
          <RouterProvider router={router} />
        </UserDataContext.Provider>
      </DataContext.Provider>
    </div>
  );
}

export default App;
