import Page from './home';

import {T_ZONING,T_USER_DATAS,T_DEDUCTED_DATAS} from '../../services/interfaces';

// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import UserDataContext                from '../../datas/contexts/UserDataContext';

const HomeInContext = () => {
  // valeurs en context
  const {reducerState,reducerDispatch} = useContext(UserDataContext);
  const {userDatas,deductedUserDatas,userZoning} = reducerState;

  const setUserDatasDispatch = (userDatas:T_USER_DATAS) => {
    reducerDispatch({type:'SET_USERDATAS',payload:{userDatas}});
  };
  const setDeductedUserDispatch = (deductedUserDatas:T_DEDUCTED_DATAS) => {
    reducerDispatch({type:'SET_DEDUCTEDUSERDATAS',payload:{deductedUserDatas}});
  };
  const setZoningDispatch = (userZoning:T_ZONING) => {
    reducerDispatch({type:'SET_USERZONING',payload:{userZoning}});
  };

  return <Page
            userDatas={userDatas}
            deductedUserDatas={deductedUserDatas}
            userZoning={userZoning}
            setZoningDispatch={setZoningDispatch}
            setUserDatasDispatch={setUserDatasDispatch}
            setDeductedUserDispatch={setDeductedUserDispatch} />;
};

export default HomeInContext;
