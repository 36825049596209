import React from 'react';

import NewsBox  from "../newsBox";
import {T_LastInfo} from '../../services/interfaces';

import './modalInfo.scss';

interface I_PROPS {
  lastInfo?: T_LastInfo;
  onClickClose: Function;
}

function ModalInfo({lastInfo,onClickClose} : I_PROPS) {
  return (
    <div className="modal">
      <div className="modal-overlay" onClick={(_e) => onClickClose()}></div>
      <div className="modal-card">
        <h2>Dernière news</h2>
        {lastInfo ? <NewsBox /> : null}
        <div className="buttons">
          <div className="closeModalButton" onClick={(_e) => onClickClose()}>OK</div>
        </div>
      </div>
    </div>
  );
}
export default ModalInfo;
