import React, {useState,useEffect,useCallback,useMemo} from 'react';
import MetaTags from 'react-meta-tags';

// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import DataContext from '../../datas/contexts/DataContext';

import Menu     from "../../components/menu";
import Version  from "../../components/version";
import Bubble  from "../../components/bubble";
import ModalInfo  from "../../components/modalInfo";
import NextBlock  from "../../components/nextBlock";
import Warning  from "../../components/warning";

import S_Utils  from "../../services/utils";
import S_Datas  from "../../services/datas";
import {T_LastInfo} from "../../services/datas";
import {T_USER_DATAS,T_DEDUCTED_DATAS,T_ZONING,T_CITY_ZONE} from '../../services/interfaces';

import './home.scss';

function isInfoBubbleActive(lastInfo:T_LastInfo|undefined,currentInfoRead:T_LastInfo|undefined) {
  if (lastInfo === undefined) {
    return false;
  }
  if (currentInfoRead === undefined) {
    return true;
  }
  return lastInfo.id !== currentInfoRead.id;
}

interface I_PROPS {
  userDatas?: T_USER_DATAS;
  deductedUserDatas?: T_DEDUCTED_DATAS;
  userZoning?: T_ZONING;

  setZoningDispatch: (userZoning:T_ZONING) => void;
  setUserDatasDispatch: (userDatas:T_USER_DATAS) => void;
  setDeductedUserDispatch: (deductedUserDatas:T_DEDUCTED_DATAS) => void;
}

function Home(props: I_PROPS) {
  const [ modalOpened, setModalOpened ] = useState<boolean>(false); // modal de selection de la zone

  const [ nextBlocksClassAnim, setNextBlocksClassAnim ] = useState('');

  // valeurs en context
  const {datasReady,lastInfo} = useContext(DataContext);

  // valeurs récupérées du reducer state, placées en context, transmises via props
  const {userDatas,deductedUserDatas,userZoning} = props;
  const {setZoningDispatch,setUserDatasDispatch,setDeductedUserDispatch} = props;

  // states pour l'info
  const [ currentInfoRead, setCurrentInfoRead ] = useState<T_LastInfo|undefined>(S_Datas.currentInfoRead);
  const [ infoBubbleActive, setInfoBubbleActive ] = useState<boolean>(isInfoBubbleActive(lastInfo,currentInfoRead));
  const [ modalInfoOpened, setModalInfoOpened ] = useState<boolean>(false); // modal d'infos

  // On calcule la date du jour qu'une seule fois au chargement,
  // pas besoin de recalculer à chaque changement de state
  const today = useMemo(
    () => S_Utils.getToday(),
    []
  );


  // on a besoin de la liste des zones possibles pour le modal de selection
  let allZonings = datasReady ? S_Datas.getAllZonings() : undefined;
  let allCitiesKeys = allZonings ? Object.keys(allZonings) : [];
  let allDiversZones = userZoning  && userZoning.city && allZonings && allZonings[userZoning.city] && allZonings[userZoning.city].divers ? allZonings[userZoning.city].divers : undefined;
  let allPlastiquesZones = userZoning  && userZoning.city && allZonings && allZonings[userZoning.city] && allZonings[userZoning.city].plastiques ? allZonings[userZoning.city].plastiques : undefined;
  let allVerreZones = userZoning  && userZoning.city && allZonings && allZonings[userZoning.city] && allZonings[userZoning.city].verre ? allZonings[userZoning.city].verre : undefined;
  let allVegetauxZones = userZoning  && userZoning.city && allZonings && allZonings[userZoning.city] && allZonings[userZoning.city].vegetaux ? allZonings[userZoning.city].vegetaux : undefined;
  let isZoningComplete = S_Datas.isZoningComplete();

  // if (datasReady) {
  //   console.log('Home - currentZoning for user',{userZoning});
  //   console.log('Home - datas for user',{userDatas});
  //   console.log('Home - deductedDatas for user',{deductedUserDatas});
  //   console.log('Home - allZonings',{allZonings});
  //   console.log('Home - isZoningComplete',{isZoningComplete});
  //   console.log('Home - allDiversZones',{allDiversZones});
  // }


  // TODO : charger infos generales en fetch get, pour que si les gens ont pas mis a jour, ils aient l'infos qd meme

  useEffect(() => {
    setTimeout(function(){
      setNextBlocksClassAnim('enter');
      setTimeout(function(){
        setNextBlocksClassAnim('stable');
      },300);
    },300);
  },[]);

  // on met a jour infoBubbleActive si lastInfo ou currentInfoRead change
  useEffect(() => {
    setInfoBubbleActive(isInfoBubbleActive(lastInfo,currentInfoRead));
  },[currentInfoRead, lastInfo]);


  function openModal(_e: any){
    setModalOpened(true);
  }
  function closeModal(_e: any){
    setModalOpened(false);
  }

  function selectCity(selectedCityID:string) {
    console.log('Select city',selectedCityID);
    S_Datas.selectCity(selectedCityID);
    isZoningComplete = S_Datas.isZoningComplete();
    const userZoning = S_Datas.currentZoning;
    setZoningDispatch(userZoning);

    // ca impacte datas et deducteddatas
    const userDatas = S_Datas.getDatas();
    setUserDatasDispatch(userDatas);
    let deductedUserDatas = userDatas ? S_Datas.getDeductedDatas(userDatas) : undefined;
    setDeductedUserDispatch(deductedUserDatas);

    setNextBlocksClassAnim('bump');
    setTimeout(function(){
      setNextBlocksClassAnim('stable');
    },600);
  }

  function selectDivers(selectedZone:string) {
    S_Datas.selectDivers(selectedZone);
    isZoningComplete = S_Datas.isZoningComplete();
    const userZoning = S_Datas.currentZoning;
    setZoningDispatch(userZoning);

    // ca impacte datas et deducteddatas
    const userDatas = S_Datas.getDatas();
    setUserDatasDispatch(userDatas);
    let deductedUserDatas = userDatas ? S_Datas.getDeductedDatas(userDatas) : undefined;
    setDeductedUserDispatch(deductedUserDatas);

    setNextBlocksClassAnim('bump');
    setTimeout(function(){
      setNextBlocksClassAnim('stable');
    },600);
  }

  function selectPlastiques(selectedZone:string) {
    S_Datas.selectPlastiques(selectedZone);
    isZoningComplete = S_Datas.isZoningComplete();
    const userZoning = S_Datas.currentZoning;
    setZoningDispatch(userZoning);

    // ca impacte datas et deducteddatas
    const userDatas = S_Datas.getDatas();
    setUserDatasDispatch(userDatas);
    let deductedUserDatas = userDatas ? S_Datas.getDeductedDatas(userDatas) : undefined;
    setDeductedUserDispatch(deductedUserDatas);

    setNextBlocksClassAnim('bump');
    setTimeout(function(){
      setNextBlocksClassAnim('stable');
    },600);
  }

  function selectVerre(selectedZone:string) {
    S_Datas.selectVerre(selectedZone);
    isZoningComplete = S_Datas.isZoningComplete();
    const userZoning = S_Datas.currentZoning;
    setZoningDispatch(userZoning);

    // ca impacte datas et deducteddatas
    const userDatas = S_Datas.getDatas();
    setUserDatasDispatch(userDatas);
    let deductedUserDatas = userDatas ? S_Datas.getDeductedDatas(userDatas) : undefined;
    setDeductedUserDispatch(deductedUserDatas);

    setNextBlocksClassAnim('bump');
    setTimeout(function(){
      setNextBlocksClassAnim('stable');
    },600);
  }

  function selectVegetaux(selectedZone:string) {
    S_Datas.selectVegetaux(selectedZone);
    isZoningComplete = S_Datas.isZoningComplete();
    const userZoning = S_Datas.currentZoning;
    setZoningDispatch(userZoning);

    // ca impacte datas et deducteddatas
    const userDatas = S_Datas.getDatas();
    setUserDatasDispatch(userDatas);
    let deductedUserDatas = userDatas ? S_Datas.getDeductedDatas(userDatas) : undefined;
    setDeductedUserDispatch(deductedUserDatas);

    setNextBlocksClassAnim('bump');
    setTimeout(function(){
      setNextBlocksClassAnim('stable');
    },600);
  }

  // Ouvre la modal lastInfo,
  // enregistre en localStorage qu'on a vu cette info
  // On utilise useCallback pour ne pas redéfinir la fonction au changement de state de Home,
  // et ainsi éviter de re-render Bubble an modifiant ses props
  const openModalInfo = useCallback(
    (_e: any) => {
      // ouvre le modal
      setModalInfoOpened(true);
      // enregistre qu'on a lu cette info
      if (lastInfo !== undefined) {
        // on enregistre dans le service
        S_Datas.setInfoRead(lastInfo);
        // on met a jour le composant
        setCurrentInfoRead(lastInfo);
        // on le met en localStorage
        if (lastInfo) {
          window.localStorage.setItem(S_Datas.STORAGE_KEY_INFOREAD,JSON.stringify(lastInfo));
        } else {
          window.localStorage.removeItem(S_Datas.STORAGE_KEY_INFOREAD);
        }
        // NB : le composant se met a jour tout seul si currentInfoRead change dans le useEffect
        console.log('Home - openModalInfo()',{lastInfo});
      }
    },
    [lastInfo]
  );

  // Ferme la modale lastInfo
  // On utilise useCallback pour ne pas redéfinir la fonction au changement de state de Home,
  // et ainsi éviter de re-render ModalInfo an modifiant ses props
  const closeModalInfo = useCallback(
    (_e: any) => {
      setModalInfoOpened(false);
    },
    []
  );



  return (
    <div className="home">

      <MetaTags>
        <title>Prochaines dates de collecte des déchets</title>
        <meta name="description" content="Les prochaines dates de collecte des déchets au Perray-en-Yvelines et aux Essarts-le-roi (78)" />
        <meta property="og:title" content="Prochaines dates de collecte des déchets" />
      </MetaTags>


      <Menu />

      <div className="dates">
        <div className="titles">
          <h1>POUBELLES</h1>
          <div className="today">Nous sommes<br/>{ today }</div>
          {datasReady ? (
            <>
            <div className="targetButton" onClick={openModal}>
              <span>{ userDatas?.labelCity }</span>
              {!isZoningComplete ? <span className="highlight">Cliquez pour sélectionner votre zone</span> : null}
            </div>
            <Warning text={userDatas?.warningCity} />
            </>
          ) : null}
        </div>
        {datasReady ? (
        <div className="nextin columns">

          {userDatas && userDatas.divers ?(
          <NextBlock
            classAnim={nextBlocksClassAnim}
            label={'Divers'}
            value={userDatas.divers.frequency}
            next={deductedUserDatas && deductedUserDatas.divers ? deductedUserDatas.divers.next : undefined}
            date={deductedUserDatas && deductedUserDatas.divers ? deductedUserDatas.divers.date : undefined}
            more={userDatas.divers.notes}
            alert={deductedUserDatas && deductedUserDatas.divers ? deductedUserDatas.divers.alert : undefined}
            svg={'bin'}
            isEmpty={!deductedUserDatas || !deductedUserDatas.divers || !deductedUserDatas.divers.hasnext}
            optClass={'divers'}
            />
          ) : null}

          {userDatas && userDatas.plastiques ?(
          <NextBlock
            classAnim={nextBlocksClassAnim}
            label={'Plastiques / Papiers'}
            value={userDatas.plastiques.frequency}
            next={deductedUserDatas && deductedUserDatas.plastiques ? deductedUserDatas.plastiques.next : undefined}
            date={deductedUserDatas && deductedUserDatas.plastiques ? deductedUserDatas.plastiques.date : undefined}
            more={userDatas.plastiques.notes}
            alert={deductedUserDatas && deductedUserDatas.plastiques ? deductedUserDatas.plastiques.alert : undefined}
            svg={'garbage'+(deductedUserDatas && deductedUserDatas.plastiques && deductedUserDatas.plastiques.alert==='imminent'?'-red':'')}
            isEmpty={!deductedUserDatas || !deductedUserDatas.plastiques || !deductedUserDatas.plastiques.hasnext}
            optClass='plastique'
            />
          ) : null}

          {userDatas && userDatas.verre ?(
          <NextBlock
            classAnim={nextBlocksClassAnim}
            label={'Verre'}
            value={userDatas.verre.frequency}
            next={deductedUserDatas && deductedUserDatas.verre ? deductedUserDatas.verre.next : undefined}
            date={deductedUserDatas && deductedUserDatas.verre ? deductedUserDatas.verre.date : undefined}
            more={userDatas.verre.notes}
            alert={deductedUserDatas && deductedUserDatas.verre ? deductedUserDatas.verre.alert : undefined}
            svg={'glass-container'+(deductedUserDatas && deductedUserDatas.verre && deductedUserDatas.verre.alert==='imminent'?'-red':'')}
            isEmpty={!deductedUserDatas || !deductedUserDatas.verre || !deductedUserDatas.verre.hasnext}
            optClass={'verre'}
            />
          ) : null}

          {userDatas && userDatas.vegetaux ?(
          <NextBlock
            classAnim={nextBlocksClassAnim}
            label={'Végétaux'}
            value={userDatas.vegetaux.frequency}
            next={deductedUserDatas && deductedUserDatas.vegetaux ? deductedUserDatas.vegetaux.next : undefined}
            date={deductedUserDatas && deductedUserDatas.vegetaux ? deductedUserDatas.vegetaux.date : undefined}
            more={userDatas.vegetaux.notes}
            alert={deductedUserDatas && deductedUserDatas.vegetaux ? deductedUserDatas.vegetaux.alert : undefined}
            svg={'recycle-bin'+(deductedUserDatas && deductedUserDatas.vegetaux && deductedUserDatas.vegetaux.alert==='imminent'?'-red':'')}
            isEmpty={!deductedUserDatas || !deductedUserDatas.vegetaux || !deductedUserDatas.vegetaux.hasnext}
            optClass={'vegetaux'}
            />
          ) : null}

        </div>
        ) : null}
      </div>

      <Version />

      <Bubble active={infoBubbleActive} onClick={openModalInfo}/>

      { datasReady && modalOpened ? (
      <div className="modal">
        <div className="modal-overlay"></div>
        <div className="modal-card">
          <h2>Ma ville, mes zones</h2>
          <div className="explain"><i>Se reporter &agrave; la carte</i></div>
          {allZonings ? (
          <div className="fields">
            <div className="cities">
              { allCitiesKeys.map((cityID) => {
                return (
                  <div key={cityID} className={'cityModalButton ' + (userZoning  && userZoning.city === cityID ? 'selected' : '')} onClick={(e) => selectCity(cityID)}>{ allZonings[cityID].labelCity }</div>
                );
              })}
            </div>
            <div>-</div>
            <div className="zones">
              {allDiversZones && Object.keys(allDiversZones).length > 1 ? (
                <div className="zone zones-divers">
                  <div className="zone-type-label">Divers</div>
                  { Object.keys(allDiversZones).map((zoneID:string) => {
                    const labelZone = allDiversZones[zoneID];
                    return (
                      <div key={zoneID} className={'zoneModalButton mini diversZoneModalButton ' + (userZoning  && userZoning.divers === zoneID ? 'selected' : '')} onClick={(e) => selectDivers(zoneID)}>{ labelZone }</div>
                    );
                  })}
                </div>
              ) : null}
              {allPlastiquesZones && Object.keys(allPlastiquesZones).length > 1 ? (
                <div className="zone zones-plastiques">
                  <div className="zone-type-label">Plastiques / Papiers</div>
                  { Object.keys(allPlastiquesZones).map((zoneID:string) => {
                    const labelZone = allPlastiquesZones[zoneID];
                    return (
                      <div key={zoneID} className={'zoneModalButton mini plastiquesZoneModalButton ' + (userZoning  && userZoning.plastiques === zoneID ? 'selected' : '')} onClick={(e) => selectPlastiques(zoneID)}>{ labelZone }</div>
                    );
                  })}
                </div>
              ) : null}
              {allVerreZones && Object.keys(allVerreZones).length > 1 ? (
                <div className="zone zones-verre">
                  <div className="zone-type-label">Verre</div>
                  { Object.keys(allVerreZones).map((zoneID:string) => {
                    const labelZone = allVerreZones[zoneID];
                    return (
                      <div key={zoneID} className={'zoneModalButton mini verreZoneModalButton ' + (userZoning  && userZoning.verre === zoneID ? 'selected' : '')} onClick={(e) => selectVerre(zoneID)}>{ labelZone }</div>
                    );
                  })}
                </div>
              ) : null}
              {allVegetauxZones && Object.keys(allVegetauxZones).length > 1 ? (
                <div className="zone zones-vegetaux">
                  <div className="zone-type-label">Végétaux</div>
                  { Object.keys(allVegetauxZones).map((zoneID:string) => {
                    const labelZone = allVegetauxZones[zoneID];
                    return (
                      <div key={zoneID} className={'zoneModalButton mini vegetauxZoneModalButton ' + (userZoning  && userZoning.vegetaux === zoneID ? 'selected' : '')} onClick={(e) => selectVegetaux(zoneID)}>{ labelZone }</div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          ) : null}
          <div className="buttons">
            <div className="closeModalButton" onClick={closeModal}>OK</div>
          </div>
        </div>
      </div>
      ) : null }

    { datasReady && modalInfoOpened ? <ModalInfo onClickClose={closeModalInfo} /> : null }
    </div>
  );
}
export default Home;
