import React from 'react';

import './bubble.scss';

interface I_PROPS {
  active: boolean;
  onClick: Function;
}

function Bubble({active,onClick} : I_PROPS) {
  function handleClick(_e:any) {
    onClick();
  }
  return (
    <div className={'bubble' + (active ? ' active':'')} onClick={handleClick}>
      <div className="bubbleButton">✉</div>
    </div>
  );
}
export default Bubble;
