import React from 'react';
import { Link } from 'react-router-dom';

import './menu.scss';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';
const LinkInMemo = memo(Link);

function Menu() {

  const sharedData = {
    title: "Appli Poubelles",
    text: "Voici l'appli que j'utilise pour connaître les prochains ramassages de poubelles :",
    url: "https://poubelles.asnidren.com",
  };
  function canBrowserShareData(data: object) {
    if (!navigator.share || !navigator.canShare) {
      return false;
    }
    return navigator.canShare(data);
  }
  const canShare = canBrowserShareData(sharedData);


  function handleShare(_e: any) {
    console.log('Menu - Share...');

    shareData().then(() => {
      console.log('Menu - Share fired.');
    });
  }

  async function shareData() {
    try {
      await navigator.share(sharedData);
      // The data was shared successfully.
    } catch (e) {
      // The data could not be shared.
      console.error(`Error: ${e}`);
    }
  }

  return (
    <div className="menu">
      <LinkInMemo to={'/'} className="homeButton"></LinkInMemo>
      <LinkInMemo to={'/documents'} className="docsButton">DOCS</LinkInMemo>
      <LinkInMemo to={'/calendrier'} className="calendarButton"></LinkInMemo>
      <LinkInMemo to={'/about'} className="helpButton">?</LinkInMemo>
      <div className={'shareButton '+(!canShare ? 'disabled' : '')} onClick={handleShare}></div>
    </div>
  );
}
export default Menu;
