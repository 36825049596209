import React from 'react';

import './document.scss';

interface I_PROPS {
  name?: string;
  path?: string;
  download?: string;
}

function I_Document({name,path,download} : I_PROPS) {

  if (!path && !name && !download)
    return null;

  const downloadName = download ? download.replace(/^.*[\\/]/, '') : '';

  const handleDownloadClick = () => {
    if (!download)
      return;
    const link = document.createElement("a");
    link.href = download;
    link.download = downloadName; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="documentBox">
      <div className="documentHeader">
        {name ? <span className="name">{ name }</span> : null}
        {download !== undefined ? (
        <span className="download">
          <div className="download-btn" onClick={handleDownloadClick} title={'Télécharger ' + downloadName}></div>
        </span>
        ) : null}
      </div>
      {path ? <img src={path} alt={name}/> : null}
    </div>
  );
}
export default I_Document;
