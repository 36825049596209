import React from 'react';

import './warning.scss';

interface I_PROPS {
  text?: string;
}

function Warning({text} : I_PROPS) {

  if (!text) {
    return null;
  }

  return <div className="warning">{text}</div>;
}
export default Warning;
