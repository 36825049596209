import React from 'react';

import './nextBlock.scss';

interface I_PROPS {
  classAnim?: string,
  value:string,
  next?:string,
  date?:string,
  more:string,
  alert?:string,
  isEmpty:boolean,
  label:string,
  svg:string
  optClass:string;
}

function NextBlock({
  classAnim,
  value,
  next,
  date,
  more,
  alert,
  isEmpty,
  label,
  svg,
  optClass,
} : I_PROPS) {

  if (isEmpty) {
    return (
      <div className={'nextBlock nextBlock-'+optClass+' disabled ' + (alert ? alert : '') + (classAnim==='bump' ? ' scale-active' : (classAnim==='enter' ? ' scale-enter' : ' scale-stable'))}>
        <div className="label"><img src={'../assets/images/'+svg+'.svg'} alt="" /> {label}</div>
        <div className="value-missing">Nous n'avons pas encore les prochaines dates</div>
      </div>
    );
  }

  return (
    <div className={'nextBlock nextBlock-'+optClass+' ' + (alert ? alert : '') + (classAnim==='bump' ? ' scale-active' : (classAnim==='enter' ? ' scale-enter' : ' scale-stable'))}>
      <div className="label"><img src={'../assets/images/'+svg+'.svg'} alt="" /> {label}</div>
      { value ? <div className="frequence">{ ''+value+'' }</div> : null }
      { next ? <div className="next"><span>{next }</span></div> : null }
      { date ? <div className="date"><img src={'../assets/images/calendar.svg'} alt="" /> <span>Le { date }</span></div> : null }
      { more ? <div className="notes" dangerouslySetInnerHTML={{__html: more}}></div> : null }
    </div>
  );
}
export default NextBlock;
