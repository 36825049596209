import {T_USER_DATAS,T_DEDUCTED_DATAS,T_ZONING} from "../../services/interfaces";

// --- DataContext ----
// On utilise le Context API pour faire descendre les datas emarquées
// sur tout l'arbre de composant
// Il n'y a pas de modifications appliquées dessus, donc pas besoin de reducer
import {createContext} from 'react';
interface I_USERDATACONTEXT_DEFAULT_VALUE {
  reducerState: {
    userDatas: T_USER_DATAS;
    deductedUserDatas: T_DEDUCTED_DATAS;
    userZoning: T_ZONING;
    userInfoRead?: string;
  },
  reducerDispatch: any
}
const USERDATACONTEXT_DEFAULT_VALUE:I_USERDATACONTEXT_DEFAULT_VALUE = {
  reducerState: {
    userDatas: {
      id: undefined,
      labelCity: undefined,
      warningCity: undefined,
      documents: undefined,
      divers: undefined,
      plastiques: undefined,
      verre: undefined,
      vegetaux: undefined,
    },
    deductedUserDatas: {
      divers: undefined,
      plastiques: undefined,
      verre: undefined,
      vegetaux: undefined,
    },
    userZoning: {
      city: undefined,
      divers: undefined,
      plastiques: undefined,
      verre: undefined,
      vegetaux: undefined
    },
    userInfoRead: undefined
  },
  reducerDispatch: undefined
};
const UserDataContext = createContext(USERDATACONTEXT_DEFAULT_VALUE);
export {USERDATACONTEXT_DEFAULT_VALUE};
export default UserDataContext;
// --- \DataContext ----
