import {T_LastInfo} from "../../services/datas";

// --- DataContext ----
// On utilise le Context API pour faire descendre les datas emarquées
// sur tout l'arbre de composant
// Il n'y a pas de modifications appliquées dessus, donc pas besoin de reducer
import {createContext} from 'react';
interface I_DATACONTEXT_DEFAULT_VALUE {
  datasReady: boolean;
  allDatas: object;
  lastInfo?: T_LastInfo;
}
const DATACONTEXT_DEFAULT_VALUE:I_DATACONTEXT_DEFAULT_VALUE = {
  datasReady: false,
  allDatas: {},
  lastInfo: undefined,
};
const DataContext = createContext(DATACONTEXT_DEFAULT_VALUE);
export default DataContext;
// --- \DataContext ----
