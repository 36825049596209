import Component from './ModalInfo';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import DataContext from '../../datas/contexts/DataContext';

const ModalInfoInContext = ({onClickClose}:{onClickClose:Function}) => {
  // valeurs en context
  const {lastInfo} = useContext(DataContext);

  return <Component lastInfo={lastInfo} onClickClose={onClickClose}/>;
};

export default memo(ModalInfoInContext);
