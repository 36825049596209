import React from 'react';

import {T_DOCUMENT} from '../../services/interfaces';
import INTERFACE from './I_Document';

interface I_PROPS {
  document?: T_DOCUMENT;
}

function Document({document} : I_PROPS) {
  if (!document)
    return null;

  return <INTERFACE name={document.name} path={document.path} download={document.download} />;
}
export default Document;
