import React from 'react';
import MetaTags from 'react-meta-tags';

import Menu from "../../components/menu";
import Version  from "../../components/version";

import './about.scss';

function About() {
  return (
    <div className="about">

      <MetaTags>
        <title>A propos de cette application</title>
        <meta name="description" content="Les prochaines dates de collecte des déchets au Perray-en-Yvelines et aux Essarts-le-roi (78)" />
        <meta property="og:title" content="A propos de cette application" />
      </MetaTags>

      <Menu />

      <div className="helpContents">
        <div className="titles">
          <h1>A propos</h1>
        </div>
        <div className="contents">
          <p>Cette page est cr&eacute;&eacute;e et maintenue &agrave; titre personnel par une habitante du Perray-en-Yvelines, et n'a aucun lien avec les services des mairies.</p>
          <p>Les informations pr&eacute;sent&eacute;es sont report&eacute;es manuellement plusieurs fois par an, à partir des documents mis &agrave; disposition par le Sictom sur son site ou sur les sites officiels des mairies, et par cons&eacute;quent peuvent faire l'objet d'erreurs.</p>
          <p>Cette page fait l'objet d'un tracking basique via le service Google Analytics, afin de conna&icirc;tre sa fr&eacute;quentation. En aucun cas vos informations personnelles, en l'occurence Ville & Zone, ne sont collect&eacute;es.</p>

          <h2>La Sictom</h2>
          <p>Pour plus d'information sur la collecte des d&eacute;chets dans notre r&eacute;gion, reportez vous au site officiel : </p>
          <p><a href="https://www.sictomregionrambouillet.com/" target="_blank">https://www.sictomregionrambouillet.com</a></p>

          <h2>Contact</h2>
          <p>Pour toute suggestion, envoyez un mail à <a href="mailto:asnidren@gmail.com" target="_blank">Asnidren</a>.</p>

          <h2>Cr&eacute;dits</h2>
          <div className="credits">
            <p>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a>, <a href="https://www.flaticon.com/authors/zlatko-najdenovski" title="Zlatko Najdenovski">Zlatko Najdenovski</a>, <a href="https://www.flaticon.com/authors/ultimatearm" title="ultimatearm">ultimatearm</a>, <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            <a href="https://www.freepik.com/icon/calendar_3917292#fromView=search&term=calendar&track=ais&page=1&position=2&uuid=321aeef5-21ab-4dcd-b729-2a745c06c2fe">Icon by UIcons</a>
          </div>
        </div>
      </div>

      <Version />

    </div>
  );
}
export default About;
